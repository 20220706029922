import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import * as styles from './image.module.scss';

interface ImageProps {
  title: string;
  style?: CSSProperties;
  src: string;
}

export default function c(props: ImageProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={styles.layout} style={props.style}>
      <div className={styles.title}>{t(props.title)}</div>
      <div className={styles.container}>
        <img src={props.src} alt={t(props.title)} />
      </div>
    </div>
  );
}
