import React from 'react';
import { useTranslation } from 'react-i18next';
import * as styles from './information.module.scss';

interface InformationProps {
  title: string;
  text: Array<string>;
}

export default function Information(props: InformationProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={styles.layout}>
      <div className={styles.title}>{t(props.title)}</div>
      <div className={styles.text}>
        {props.text.map((txt) => (
          <div key={txt}>{t(txt)}</div>
        ))}
      </div>
    </div>
  );
}
