import React from 'react';
import '../i18n/i18n';
import HeaderBloc from '../components/bloc/header-bloc/headerBloc';
import Image from '../components/bloc/image/image';
import Information from '../components/bloc/information/information';
import LayoutBloc from '../components/bloc/layout-bloc/layoutBloc';
import case1Image1 from '../assets/case1_img_1@2x.webp';
import case1Image2 from '../assets/case1_img_2@2x.webp';
import case1Image3 from '../assets/case1_img_3@2x.webp';
import case1Image4 from '../assets/case1_img_4@2x.webp';
import Layout from '../components/layout';

export default function NewSignupExperience(): JSX.Element {
  return (
    <Layout>
      <LayoutBloc>
        <HeaderBloc
          title="USE_CASE_1.BLOC_TITLE"
          description="USE_CASE_1.DESCRIPTION"
          info="Research / UX / UI / mobile"
          backgroundColor="#FAF3D5"
          style={{ backgroundColor: '#FAF3D5' }}
          img={case1Image1}
        />
        <div className="contentBloc">
          <Information title="USE_CASE_1.CHALLENGE_TITLE" text={['USE_CASE_1.CHALLENGE_TEXT']} />
          <Image title="USE_CASE_1.IDENTIFYING_POINT" style={{ backgroundColor: '#F4F5FA' }} src={case1Image2} />
          <Information
            title="USE_CASE_1.RESEARCH_INSIGHTS_TITLE"
            text={[
              'USE_CASE_1.RESEARCH_INSIGHTS_1',
              'USE_CASE_1.RESEARCH_INSIGHTS_2',
              'USE_CASE_1.RESEARCH_INSIGHTS_3',
              'USE_CASE_1.RESEARCH_INSIGHTS_4',
              'USE_CASE_1.RESEARCH_INSIGHTS_5',
              'USE_CASE_1.RESEARCH_INSIGHTS_6',
            ]}
          />
          <Image title="USE_CASE_1.IDENTIFYING_POINT" style={{ backgroundColor: '#F4F5FA' }} src={case1Image3} />
          <Information title="USE_CASE_1.TEST_TITLE" text={['USE_CASE_1.TEST_TEXT']} />
          <Image title="USE_CASE_1.VALIDATED_PROTOTYPES" style={{ backgroundColor: '#FAF3D5' }} src={case1Image4} />
          <Information
            title="USE_CASE_1.RESULT_LEARNINGS_TITLE"
            text={['USE_CASE_1.RESULT_LEARNINGS_1', 'USE_CASE_1.RESULT_LEARNINGS_2', 'USE_CASE_1.RESULT_LEARNINGS_3']}
          />
        </div>
      </LayoutBloc>
    </Layout>
  );
}
