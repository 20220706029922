import React, { CSSProperties, useRef } from 'react';
import * as styles from './image-cut.module.scss';

interface HeaderProps {
  title: string;
  img: string;
  backgroundColor: string;
  style?: CSSProperties;
}

export default function ImageCut(props: HeaderProps): JSX.Element {
  const ref = useRef();
  const [backgroundHeight, setBackgroundHeight] = React.useState(0);

  function handleResize() {
    const height = document.getElementById('picture')?.clientHeight;
    if (height) {
      setBackgroundHeight(height / 2);
    }
  }

  const timer = setInterval(function () {
    const height = ref?.current?.getBoundingClientRect().height;
    if (height) {
      setBackgroundHeight(height / 2);
    }
    if (height !== 0) clearInterval(timer);
  }, 200);

  React.useEffect(() => {
    typeof window !== 'undefined' && window.addEventListener('resize', handleResize);

    return (_) => {
      typeof window !== 'undefined' && window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.background} style={{ height: backgroundHeight, backgroundColor: props.backgroundColor }} />
      <img id="picture" src={props.img} alt={props.title} ref={ref} />
    </div>
  );
}
