import React from 'react';
import * as styles from './layout-bloc.module.scss';

interface LayoutBlocProps {
  children?: React.ReactNode;
}

export default function LayoutBloc({ children }: any): JSX.Element {
  return <div className={styles.layout}>{children}</div>;
}
