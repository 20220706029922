import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import * as styles from './header-bloc.module.scss';
import ImageCut from './image-cut/image-cut';

interface HeaderProps {
  description: string;
  title: string;
  info: string;
  img: string;
  backgroundColor: string;
  style?: CSSProperties;
}

export default function HeaderBloc(props: HeaderProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={styles.headerLayout}>
      <div className={styles.textLayout} style={props.style}>
        <div className={styles.title}>{t(props.title)}</div>
        <div className={styles.description}>{t(props.description)}</div>
        <div className={styles.info}>{props.info}</div>
      </div>
      <ImageCut title={t(props.title)} img={props.img} backgroundColor={props.backgroundColor} />
    </div>
  );
}
